import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PartsManualIcon from "../components/individualProductPage/images/PartsManual-Icon-hover.png"
import OperatorManual from "../components/individualProductPage/images/OperatorManual-FC-Icon-hover.png"

const Container = styled.div`
  max-width: 800px;
  /* border: 2px solid black; */

  h2 {
    text-decoration: underline;
  }

  .icon-container {
    display: flex;
    justify-content: space-around;

    img {
      width: 100px;
    }

    a {
      text-decoration: none;
      color: black;
    }
  }
`

const ManualData = props => {
  const [manualData, setManualData] = useState({})

  function getSerialData() {
    let url = `https://www.tomcatequip.com/BornOnEP.php?action=manuals&serial=${props.serialNumber}`

    const CORS_PROXY = "https://factorycat-proxy.herokuapp.com/?q="
    fetch(`${CORS_PROXY}${url}`)
      .then(function(response) {
        return response.json()
      })
      .then(function(data) {
        console.log(data)
        setManualData(data)
      })
      .catch(function(error) {
        console.warn("Something went wrong.", error)
      })
  }

  useEffect(() => {
    getSerialData()
  })
  return (
    <Container>
      <h2>Manuals</h2>
      <div className="icon-container">
        <a href={manualData.FCPARTS} target="_blank" rel="noreferrer">
          <img src={PartsManualIcon} />
          <p>Parts Manual</p>
        </a>
        <a href={manualData.FCOP} target="_blank" rel="noreferrer">
          <img src={OperatorManual} />
          <p>Operator Manual</p>
        </a>
      </div>
    </Container>
  )
}

export default ManualData
