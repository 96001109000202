import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import styled from "styled-components"
import BornOnIcon from "../images/BornOn-Icon-hover.png"
import ManualIcon from "../images/Manuals-Icon-hover.png"
import PmIcon from "../images/PM-Icon-hover.png"
import RegisterIcon from "../images/Register-Icon-hover.png"

import ManualData from "../components/ManualData"
import PMData from "../components/individualProductPage/PMData"
import { Tabs, Tab, TabsContent } from "../components/Tabs"
import BornOnInfo from "../components/BornOnInfo"
import ManualsData from "../components/ManualsData"
import PartsIcon from "../images/parts-icon-150h.png"
import OpsIcon from "../images/operator-icon-150h.png"
import PmPdfIcon from "../images/prevent-logo.png"
import PmOnlineIcon from "../images/prevent-online-logo.png"

const PageLayout = styled.div`
  padding-top: 35px;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`
const SerialInput = styled.input`
  width: 90%;
  margin: 0.5rem auto;
  padding: 0.5rem 0.5rem;
`
const SubmitButton = styled.button`
  width: 93%;
  margin: 0.5rem auto;
  padding: 0.5rem 0;
  background-color: red;
  color: white;
  border: none;
  font-size: 1em;
  cursor: pointer;
`

const DataContainer = styled.div`
  text-align: center;
  h2,
  h3 {
    margin: 0;
  }
`

const ButtonContainer = styled.div`
  max-width: 1000px;
  justify-content: space-around;
  margin: 2rem auto;
  display: flex;
  flex-wrap: wrap;
`
const IconContainer = styled.div`
  max-width: 100px;
  text-align: center;

  cursor: pointer;
  img {
    width: 80px;
    margin: 0;
  }

  p {
    font-size: 1em;
    margin: 0;
  }
`

const BackBtn = styled.button`
  background-color: red;
  border: none;
  color: white;
  padding: 0.5rem;
  cursor: pointer;
`
const ManualDataContainer = styled.div`
  display: flex;
  justify-content: space-around;
`
const ManualImageContainer = styled.div`
  text-align: center;
  img {
    height: 100px;
  }

  a {
    text-decoration: "none";
    color: black;
  }
`

const ManualContainer = styled.div`
  h2,
  h3 {
    margin: 0;
  }
`

const PreventContainer = styled.div`
  h2,
  h3 {
    margin: 0;
  }
`

const BornOnInfoContainer = styled.div``

const BornOnDataContainer = styled.div`
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-gap: 1rem;
  margin: 0 auto;
  /* border: 1px solid black; */

  .data-title {
    font-weight: bold;
    text-align: left;
    margin: 0;
  }

  .data-data {
    text-align: left;
    margin: 0;
  }
`

const SerialPage = props => {
  const [serialNumber, setSerialNumber] = useState("")
  const [serialData, setSerialData] = useState([])
  const [updatedSerialNumber, setUpdatedSerialNumber] = useState(54321)
  const [showStatic, setShowStatic] = useState("none")
  const [showBtnContainer, setShowBtnContainer] = useState("none")
  // const [brushArray, setBrushArray] = useState([])
  const [bornOnInfo, setBornOnInfo] = useState("none")
  const [mainData, setMainData] = useState("none")
  const [manualData, setManualBornOnData] = useState("none")
  const [manualLinks, setManualLinks] = useState({})
  const [preventData, setPreventData] = useState("none")

  function handleChange(e) {
    setSerialNumber(e.target.value)
  }

  function toggleManualData(e) {
    e.preventDefault()
    setManualBornOnData("block")
    setMainData("none")
    setPreventData("none")
    setBornOnInfo("none")
  }

  function toggleMainData(e) {
    e.preventDefault()
    setBornOnInfo("none")
    setManualBornOnData("none")
    setPreventData("none")
    setMainData("block")
  }

  function toggleBornOnInfo(e) {
    e.preventDefault()
    setBornOnInfo("block")
    setMainData("none")
    setManualBornOnData("none")
    setPreventData("none")
  }

  function togglePreventInfo(e) {
    e.preventDefault()
    setBornOnInfo("none")
    setMainData("none")
    setManualBornOnData("none")
    setPreventData("block")
  }

  const CORS_PROXY = "https://factorycat-proxy.herokuapp.com/?q="
  const url = "https://www.tomcatequip.com/BornOnEP.php?action=bornonsm&serial="
  const manualUrl =
    "https://www.tomcatequip.com/BornOnEP.php?action=manuals&serial="

  async function handleManualData(sn) {
    const response = await fetch(`${CORS_PROXY}${manualUrl}${sn}`)
    response.json().then(response => setManualLinks(response))
  }

  async function handleData(sn) {
    const response = await fetch(`${CORS_PROXY}${url}${sn}`)
    response.json().then(response => setSerialData(response))
  }

  // async function preventData(sn) {
  //   const response = await fetch(`${CORS_PROXY}${url}${sn}`)
  //   response.json().then(response => setPreventData(response))
  // }

  useEffect(() => {
    handleData(updatedSerialNumber)
    handleManualData(updatedSerialNumber)
  }, [updatedSerialNumber])

  function handleClick(e) {
    e.preventDefault()
    setUpdatedSerialNumber(serialNumber)
    setSerialNumber("")
    setShowStatic("inline-block")
    setShowBtnContainer("flex")
    setMainData("block")
  }

  return (
    <Layout>
      <PageLayout>
        <h2>Please enter serial number:</h2>
        <form>
          <SerialInput
            onChange={handleChange}
            type="text"
            name="serial-number"
            value={serialNumber}
          />
          <br />
          <SubmitButton onClick={handleClick}>Submit</SubmitButton>
        </form>
        <DataContainer style={{ display: mainData }}>
          <h2
            style={{
              color: serialData.regStatuscolor,
            }}
          >
            {serialData.serialNo} {""}
            <span style={{ display: showStatic }}> - </span>{" "}
            <span>{serialData.regStatus}</span>
          </h2>
          <h3 style={{ color: serialData.contactStatuscolor }}>
            {serialData.contactStatus}
          </h3>
          <h3
            style={{
              color: serialData.regStatuscolor,
            }}
          >
            {serialData.installStatus}
          </h3>
          <h3>
            <span style={{ display: showStatic }}>Factory Cat </span>{" "}
            {serialData.machineDescription}
          </h3>
          <h2 style={{ display: showStatic, textDecoration: "underline" }}>
            Authorized Local Distributor
          </h2>
          <h3>{serialData.primaryServiceDealer}</h3>
          <h3>{serialData.primaryServiceDealer_phone}</h3>
          <h3>
            <span style={{ display: showStatic }}>Installed On: </span>{" "}
            {serialData.installedOn}
          </h3>
          {serialData.Brushes &&
            serialData.Brushes.map(brush => {
              return (
                <div>
                  {brush[0]} : {brush[1]}
                </div>
              )
            })}
          {/* {setBrushArray(serialData.Brushes)} */}
        </DataContainer>
        <BornOnInfoContainer style={{ display: bornOnInfo }}>
          <h2>Born on Results for Serial Number {serialData.serialNo}</h2>
          <h3>FactoryCat {serialData.machineDescription}</h3>
          {serialData.Brushes &&
            serialData.Brushes.map(brush => {
              return (
                <BornOnDataContainer>
                  <div className="data-title">{brush[0]}</div>{" "}
                  <div className="data-data">{brush[1]}</div>
                </BornOnDataContainer>
              )
            })}
          {serialData.OtherParts &&
            serialData.OtherParts.map(otherPart => {
              return (
                <BornOnDataContainer>
                  <div className="data-title">{otherPart[0]}</div>{" "}
                  <div className="data-data">{otherPart[1]}</div>
                </BornOnDataContainer>
              )
            })}

          <BornOnDataContainer>
            <div className="data-title">Labor Warranty End Date/Days</div>
            <div style={{ color: serialData.laborcolor }} className="data-data">
              {serialData.laborend} / {serialData.laborleft}
            </div>
            <div className="data-title">Parts Warranty End Date/Days</div>
            <div style={{ color: serialData.partscolor }} className="data-data">
              {serialData.partsend} / {serialData.partsleft}
            </div>
            <div className="data-title">Travel Warranty End Date/Days</div>
            <div
              style={{ color: serialData.travelcolor }}
              className="data-data"
            >
              {serialData.travelend} / {serialData.travelleft}
            </div>
            <div className="data-title">Machine Shipped On</div>
            <div className="data-data">{serialData.installedOn}</div>
            <div className="data-title">Machine Installed On</div>
            <div className="data-data">{serialData.installedOn}</div>
            <div className="data-title">Machine Serviced By</div>
            <div className="data-data">{serialData.primaryServiceDealer}</div>
            <div className="data-title">Installers Phone Number</div>
            <div className="data-data">
              {serialData.primaryServiceDealer_phone}
            </div>
          </BornOnDataContainer>

          {serialData.InstalledOptions &&
            serialData.InstalledOptions.map(installOption => {
              return (
                <BornOnDataContainer style={{ display: "block" }}>
                  <div className="data-title">Options:</div> <br />
                  <div className="data-data">
                    {installOption[1]} - {installOption[0]}
                  </div>
                </BornOnDataContainer>
              )
            })}

          {/* {serialData.InstalledOptions &&
            serialData.InstalledOptions.map(installedOption => {
              return <p>{installedOption}</p>
            })} */}
          <BackBtn onClick={toggleMainData}>
            <i className="fas fa-arrow-left" /> Back to serial number
          </BackBtn>
        </BornOnInfoContainer>
        <ManualContainer style={{ display: manualData }}>
          <h2>Manuals for Serial Number {serialData.serialNo}</h2>
          <h3>{serialData.machineDescription}</h3>
          <h3 style={{ color: serialData.regStatuscolor }}>
            {serialData.serialNo} - {serialData.regStatus}
          </h3>
          <br />
          <h3 style={{ textDecoration: "underline" }}>Primary Dealer</h3>
          <h3>{serialData.primaryServiceDealer}</h3>
          <h3>{serialData.primaryServiceDealer_phone}</h3>

          <ManualDataContainer>
            <ManualImageContainer>
              <a href={manualLinks.FCPARTS} target="_blank" rel="noreferrer">
                <img src={PartsIcon} alt="download parts manual" />
                <p>Parts Manual</p>
              </a>
            </ManualImageContainer>
            <ManualImageContainer>
              <a href={manualLinks.FCOP} target="_blank" rel="noreferrer">
                <img src={OpsIcon} alt="download parts manual" />
                <p>Operations Manual</p>
              </a>
            </ManualImageContainer>
          </ManualDataContainer>
          <BackBtn onClick={toggleMainData}>
            <i className="fas fa-arrow-left" /> Back to serial number
          </BackBtn>
        </ManualContainer>
        <PreventContainer style={{ display: preventData }}>
          <h2>
            Preventative Maintenance for Serial Number {serialData.serialNo}
          </h2>
          <h3>{serialData.machineDescription}</h3>
          <h3 style={{ textDecoration: "underline" }}>Primary Dealer</h3>
          <h3>{serialData.primaryServiceDealer}</h3>
          <h3>{serialData.primaryServiceDealer_phone}</h3>
          <h3>Installed On: {serialData.installedOn}</h3>
          <ManualDataContainer>
            <ManualImageContainer>
              <a href={manualLinks.FCPREVMAIN} target="_blank" rel="noreferrer">
                <img src={PmPdfIcon} alt="preventative maintenance pdf" />
                <p>Preventative Maintenance PDF</p>
              </a>
            </ManualImageContainer>
            <ManualImageContainer>
              <a
                href={`http://www.rpscorporation.com/m/pmstart.php?serial=${serialData.serialNo}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={PmOnlineIcon} alt="preventative maintenance online" />
                <p>Preventative Maintenance Online</p>
              </a>
            </ManualImageContainer>
          </ManualDataContainer>
          <BackBtn onClick={toggleMainData}>
            <i className="fas fa-arrow-left" /> Back to serial number
          </BackBtn>
        </PreventContainer>
        {/* <div style={{ display: bornOnInfo }}>
          <BornOnInfo serialNumber={updatedSerialNumber} />
        </div> */}
        {/* <div style={{ display: manualData }}>
          <ManualsData
            serialData={serialData}
            serialNumber={updatedSerialNumber}
          />
        </div> */}
      </PageLayout>
      <ButtonContainer style={{ display: showBtnContainer }}>
        <IconContainer onClick={toggleManualData}>
          <img src={ManualIcon} alt="born on info" />
          <p>Manuals</p>
        </IconContainer>
        <IconContainer onClick={togglePreventInfo}>
          <img src={PmIcon} alt="born on info" />
          <p>Preventative Maintenance</p>
        </IconContainer>
        <IconContainer onClick={toggleBornOnInfo}>
          <img src={BornOnIcon} alt="born on info" />
          <p>Born On Info</p>
        </IconContainer>
        <Link
          style={{ textDecoration: "none", color: "black" }}
          to="/machine-registration"
        >
          <IconContainer>
            <img src={RegisterIcon} alt="born on info" />
            <p>Register Your Machine</p>
          </IconContainer>
        </Link>
      </ButtonContainer>
    </Layout>
  )
}

export default SerialPage
