import React, { useState, useEffect } from "react"
import PartsIcon from "../images/parts-icon-150h.png"
import OpsIcon from "../images/operator-icon-150h.png"
import styled from "styled-components"

const IconContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

const ManualContainer = styled.div`
  text-align: center;
  img {
    height: 80px;
  }
  a {
    text-decoration: none;
    color: black;
  }
`

const ManualsData = props => {
  const [manualData, setManualData] = useState({})
  const [serialData, setSerialData] = useState(props.serialData)

  const CORS_PROXY = "https://factorycat-proxy.herokuapp.com/?q="
  const url = `https://www.tomcatequip.com/BornOnEP.php?action=manuals&serial=`

  let updatedUrl = `${url}${props.serialNumber}`

  console.log(`what is the updated url? ${updatedUrl}`)

  async function handleManualData() {
    const response = await fetch(`${updatedUrl}`)
    console.log(`bornOnDateInfo - ${response}`)

    response.json().then(response => setManualData(response))
  }

  useEffect(() => {
    handleManualData()
  }, [])

  return (
    <>
      <IconContainer>
        <ManualContainer>
          <a href={manualData.FCPARTS} target="_blank" rel="noreferrer">
            <img src={PartsIcon} alt="parts manual" />
            <p>Parts Manual</p>
          </a>
        </ManualContainer>
        <ManualContainer>
          <a href={manualData.FCOP} target="_blank" rel="noreferrer">
            <img src={OpsIcon} alt="parts manual" />
            <p>Operators Manual</p>
          </a>
        </ManualContainer>
      </IconContainer>
      <div>
        <div>This is Manual Data</div>
        {manualData.FCPARTS}
        <p>{serialData.serialNumber}</p>
      </div>
    </>
  )
}

export default ManualsData
