import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PmManualIcon from "../../components/individualProductPage/images/PDF-PM-Icon2-hover.png"
import PmOnlineIcon from "../../components/individualProductPage/images/Online-PM-Icon-hover.png"

const Container = styled.div`
  max-width: 800px;
  /* border: 2px solid black; */

  h2 {
    text-decoration: underline;
  }

  .icon-container {
    display: flex;
    justify-content: space-around;

    img {
      width: 100px;
    }

    a {
      text-decoration: none;
      color: black;
    }
  }
`

const PMData = props => {
  const [manualData, setManualData] = useState({})

  function getSerialData() {
    let url = `https://www.tomcatequip.com/BornOnEP.php?action=manuals&serial=${props.serialNumber}`

    const CORS_PROXY = "https://factorycat-proxy.herokuapp.com/?q="
    fetch(`${CORS_PROXY}${url}`)
      .then(function(response) {
        return response.json()
      })
      .then(function(data) {
        console.log(data)
        setManualData(data)
      })
      .catch(function(error) {
        console.warn("Something went wrong.", error)
      })
  }

  useEffect(() => {
    getSerialData()
  })

  return (
    <Container>
      <h2>Preventative Maintenance</h2>
      <div className="icon-container">
        <a href={manualData.FCPREVMAIN} target="_blank">
          <img src={PmManualIcon} />
          <p>Preventative Maintenance [PDF]</p>
        </a>
        <a
          href={`http://www.rpscorporation.com/m/pmstart.php?serial=${props.serialNumber}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={PmOnlineIcon} />
          <p>Preventative Maintenance Online</p>
        </a>
      </div>
    </Container>
  )
}

export default PMData
