import styled from "styled-components"

export const Tabs = styled.div`
  overflow: hidden;
  max-width: 1400px;
  margin: 1rem auto;
  border: 2px solid black;
`

export const Tab = styled.button`
  background-color: ${props => (props.primary ? "blue" : "white")};
`
export const Content = styled.div`
  ${props => (props.active ? "" : "display:none")}
`
